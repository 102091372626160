<template>
  <div class="zt-page-content">
    <div class="page-header" style="min-width: 1279px">报表管理</div>
    <div class="zt-block" style="height: 700px; width: 1239px">
      <el-row style="background: #fffff">
        <el-col :span="8" style="padding: 0 1px">
          <div id="warehousing" style="height: 270px"></div>
        </el-col>
        <el-col :span="8" style="padding: 0 1px">
          <div id="warehouse" style="height: 270px"></div>
        </el-col>
        <el-col :span="8" style="">
          <div id="reduce4" style="height: 270px"></div>
        </el-col>
        <el-col :span="12" style="padding-right: 1px; margin-top: 2px">
          <div id="reduce" style="height: 428px"></div>
        </el-col>
        <el-col :span="12" style="padding-left: 1px; margin-top: 2px">
          <div id="deliver" style="height: 428px"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { WLBASE_URL } from "@/config";
import echarts from "echarts";
export default {
  components: {},

  data() {
    return {};
  },
  mounted() {
    this.warehousing();
    this.warehouse();
    this.reduce4();
    this.reduce();
    this.deliver();
  },
  methods: {
    warehousing() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/ReportWareHouIn";
      this.axios({
        url,
        method: "POST",
      }).then((res) => {
        if (res.data.success) {
          let warehousing = echarts.init(
            document.getElementById("warehousing")
          );
          let list = res.data.content;
          warehousing.setOption({
            title: {
              text: "入库汇总表",
              left: "center",
              top: 20,
              textStyle: {
                color: "#000",
                fontSize: 16,
              },
            },
            tooltip: {
              trigger: "item",
              formatter: "入库量 <br/>{b} : {c} ({d}%)",
            },
            legend: {
              formatter: function (name) {
                return name.length > 8 ? name.substr(0, 8) + "..." : name;
              },
              orient: "vertical",
              left: 7,
              top: 45,
              textStyle: {
                color: "#000",
                fontSize: "12",
              },
            },
            series: [
              {
                name: "访问来源",
                type: "pie",
                radius: ["10%", "60%"],
                center: ["60%", "60%"],
                // radius: "60%",
                // center: ["60%", "50%"],
                data: [],
                roseType: "radius",
                label: {
                  show: false,
                  color: "#000",
                },
                emphasis: {
                  label: {
                    show: false,
                  },
                },
                labelLine: {
                  lineStyle: {
                    color: "#888",
                  },
                },
                itemStyle: {
                  normal: {
                    color: function (params) {
                      var colorList = [
                        "#0278e6",
                        "#34d160",
                        "#fcdf39",
                        "#f19611",
                        "#00c6ff",
                      ];
                      return colorList[params.dataIndex % colorList.length];
                    },
                  },
                },
                animationType: "scale",
                animationEasing: "elasticOut",
                animationDelay: function (idx) {
                  return Math.random() * 200;
                },
              },
            ],
          });
          warehousing.setOption({
            series: [{ data: list }],
          });
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
    warehouse() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/ReportWareHouOut";
      this.axios({
        url,
        method: "POST",
      }).then((res) => {
        if (res.data.success) {
          let warehouse = echarts.init(document.getElementById("warehouse"));
          let list = res.data.content;
          warehouse.setOption({
            title: {
              text: "出库汇总表",
              left: "center",
              top: 20,
              textStyle: {
                color: "#000",
                fontSize: 16,
              },
            },
            tooltip: {
              trigger: "item",
              formatter: "出库量 <br/>{b} : {c} ({d}%)",
            },
            legend: {
              formatter: function (name) {
                return name.length > 8 ? name.substr(0, 8) + "..." : name;
              },
              orient: "vertical",
              left: 7,
              top: 45,
              textStyle: {
                color: "#000",
                fontSize: "13",
              },
            },
            series: [
              {
                name: "访问来源",
                type: "pie",
                radius: "60%",
                center: ["60%", "60%"],
                data: [],
                emphasis: {
                  label: {
                    show: false,
                  },
                },
                roseType: "radius",
                label: {
                  show: false,
                  color: "#000",
                },
                labelLine: {
                  lineStyle: {
                    color: "#888",
                  },
                },
                itemStyle: {
                  normal: {
                    color: function (params) {
                      var colorList = [
                        "#ffff99",
                        "#00ffcc",
                        "#00ff99",
                        "#33ccff",
                        "#388df6",
                      ];
                      return colorList[params.dataIndex % colorList.length];
                    },
                  },
                },
                animationType: "scale",
                animationEasing: "elasticOut",
                animationDelay: function (idx) {
                  return Math.random() * 200;
                },
              },
            ],
          });
          warehouse.setOption({
            series: [{ data: list }],
          });
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
    reduce4() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/ReportDispatch";
      this.axios({
        url,
        method: "POST",
      }).then((res) => {
        if (res.data.success) {
          let reduce4 = echarts.init(document.getElementById("reduce4"));
          let name = res.data.content.map((item) => {
            return item.name;
          });
          let value = res.data.content.map((item) => {
            return item.value;
          });
          var salvProMax = []; //背景按最大值
          for (let i = 0; i < value.length; i++) {
            salvProMax.push(value[0]);
          }
          reduce4.setOption({
            backgroundColor: "#fff",
            title: {
              text: "调度方案统计表",
              left: "center",
              top: 20,
              textStyle: {
                color: "#000",
                fontSize: 16,
              },
            },
            tooltip: {
              formatter: "调度量 <br/>{b} : {c} ",
              // trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "-10%",
              top: "20%",
              // containLabel: true,
            },
            xAxis: {
              type: "value",
              show: false,
              boundaryGap: [0, 0.01],
            },
            yAxis: {
              // show: false,

              data: [],
              max: 6,
              min: 1,
              type: "category",
              inverse: true,
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#000",
                  align: "left",
                },
                height: 50,
                rich: {},
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
            },

            series: [
              {
                type: "bar",
                data: [],
                barWidth: 20,
                itemStyle: {
                  normal: {
                    barBorderRadius: 30,
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "rgb(57,89,255,1)",
                      },
                      {
                        offset: 1,
                        color: "rgb(46,200,207,1)",
                      },
                    ]),
                  },
                },
              },
            ],
          });
          reduce4.setOption({
            yAxis: [{ data: name }],
            series: [{ data: value }],
          });
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
    reduce() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/GetDeliverData";
      this.axios({
        url,
        method: "POST",
      }).then((res) => {
        if (res.data.success) {
          let reduce = echarts.init(document.getElementById("reduce"));
          let name = res.data.content.map((item) => {
            return item.month;
          });
          let value = res.data.content.map((item) => {
            return item.value;
          });
          reduce.setOption({
            title: {
              text: "运单发货表",
              left: "center",
              bottom: "85%",
              textStyle: {
                color: "#000",
                fontSize: 16,
              },
            },
            grid: {
              top: "20%",
              left: "10%",
              right: "10%",
              bottom: "15%",
              containLabel: true,
            },
            xAxis: {
              name: "月",
              type: "category",
              position: "left",
              boundaryGap: false,
              data: [],
              axisLabel: {
                margin: 30,
                color: "#00000063",
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: true,
                length: 25,
                lineStyle: {
                  color: "#0000001f",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#0000001f",
                },
              },
            },
            yAxis: [
              {
                name: "发货量",
                type: "value",
                position: "left",
                axisLabel: {
                  margin: 20,
                  color: "#00000063",
                },

                axisTick: {
                  show: false,
                  length: 15,
                  lineStyle: {
                    color: "#0000001f",
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#0000001f",
                  },
                },
                axisLine: {
                  show: false,
                  lineStyle: {
                    color: "#000",
                    width: 2,
                  },
                },
              },
            ],
            series: [
              {
                name: "注册总量",
                type: "line",
                smooth: true, //是否平滑曲线显示
                showAllSymbol: true,
                symbol: "circle",
                symbolSize: 6,
                lineStyle: {
                  normal: {
                    color: "#000", // 线条颜色
                  },
                },
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#000",
                  },
                },
                itemStyle: {
                  color: "red",
                  borderColor: "#fff",
                  borderWidth: 3,
                },
                tooltip: {
                  show: false,
                },
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#06a7ff",
                        },
                        {
                          offset: 1,
                          color: "#3fbbff0d",
                        },
                      ],
                      false
                    ),
                  },
                },
                data: [],
              },
            ],
          });
          reduce.setOption({
            xAxis: [{ data: name }],
            series: [{ data: value }],
          });
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
    deliver() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/GetDeliverData";
      this.axios({
        url,
        method: "POST",
      }).then((res) => {
        if (res.data.success) {
          let deliver = echarts.init(document.getElementById("deliver"));
          let name = res.data.content.map((item) => {
            return item.month;
          });
          let value = res.data.content.map((item) => {
            return item.value;
          });
          deliver.setOption({
            title: {
              text: "发货明细表",
              left: "center",
              bottom: "85%",
              textStyle: {
                color: "#000",
                fontSize: 16,
              },
            },
            tooltip: {
              // trigger: "axis",
              axisPointer: {
                type: "shadow",
                label: {
                  show: false,
                },
              },
            },
            grid: {
              left: "7%",
              top: "18%",
              right: "5%",
              bottom: "20%",
            },
            xAxis: {
              data: [],
              name: "月",
              axisLine: {
                show: true, //隐藏X轴轴线
                lineStyle: {
                  color: "#3d5269",
                  width: 1,
                },
              },
              axisTick: {
                show: true, //隐藏X轴刻度
                alignWithLabel: true,
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#000", //X轴文字颜色
                  fontSize: 14,
                },
                interval: 0,
              },
            },
            yAxis: [
              {
                type: "value",
                name: "发货量",
                nameTextStyle: {
                  color: "#000",
                  fontSize: 12,
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    width: 1,
                    color: "#3d5269",
                  },
                },
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "#396A87",
                    fontSize: 12,
                  },
                },
              },
            ],
            series: [
              {
                name: "发货量",
                type: "bar",
                barWidth: 18,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#00FFFF",
                      },
                      {
                        offset: 1,
                        color: "#0080FF",
                      },
                    ]),
                  },
                },
                data: [],
              },
            ],
          });
          deliver.setOption({
            xAxis: [{ data: name }],
            series: [{ data: value }],
          });
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.tab {
  padding: 0 20px;
}
.tab_menu .menu_item {
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  margin-right: 50px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur {
  color: #4d4d4d;
  border-bottom: 2px solid #4d4d4d;
}

div >>> .el-table .cell {
  text-align: center;
}
div >>> .el-dialog {
  min-width: 550px;
}
div >>> .el-dialog__body {
  /* background: #f5f5f5; */
  background: #fff;
}
.xqtitle {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  line-height: 24px;
  padding: 0 2px;
  display: inline-block;
  margin-bottom: 20px;
}
.xqtitle::after {
  position: absolute;
  bottom: -4px;
  height: 4px;
  width: 100%;
  background: #5074ee;
  content: "";
  left: 0;
}
.left {
  display: inline-block;
}
</style>
